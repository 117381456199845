import CONFIG from 'lib/CONFIG';

const FROALA_KEY = CONFIG.REACT_APP_FROALA;

export const EditorConfig = {
    key: FROALA_KEY,
    attribution: false,
    quickInsertEnabled: false,
    placeholderText: 'Bemerkning..',
    charCounterCount: false,
    imagePaste: false,
    listAdvancedTypes: false,
    spellcheck: false,
    language: 'nb',
  

    toolbarButtons: {
      moreText: {
        buttons: [
          'bold',
          'italic',
          'underline',
          '|',
          'specialCharacters',
          '|',
          'strikeThrough',
          'formatOL',
          'formatUL',
          '|',
          'undo',
          'redo',
          'tekstbiter'
        ],
        align: 'left',
        buttonsVisible: 13,
      },
    },
    toolbarButtonsMD: {
      moreText: {
        buttons: [
          'bold',
          'italic',
          'underline',
          '|',
          'specialCharacters',
          '|',
          'formatOL',
          'formatUL',
          '|',
          'tekstbiter'
          
         
        ],
        align: 'left',
        buttonsVisible: 10,
      },
    },
    toolbarButtonsXS: {
      moreText: {
        buttons: [
          'bold',
          'italic',
          'underline',
          '|',
          'formatOL',
          'formatUL',
          '|',
          'specialCharacters',
          'tekstbiter'
         
        ],
        align: 'left',
        buttonsVisible: 7,
      },
    },

    tabSpaces: 4,
    heightMin: '170px',
    heightMax: '180px',
    resize: false
  };
